
import { InviteStatus } from "../../../constants/InviteStatus";
import { Base } from "../Base";
import { User } from "../user/User";
import { PlayerDto, PlayerGamePlayerDto } from "./PlayerDto";


export class Player extends Base {
  user: User;
  elo: number;
  lastOnlineAt: number;
  onlineStatus: string;
  experience: number;
  experienceLevel: number;

  selected: boolean; // selected is the property name for generic dropdown, so we will just used this instead of invited.
  name: string; // needed for dropdown purposes

  constructor(data: PlayerDto) {
    super(data)

    this.user = new User(data.user);
    this.elo = data.elo;
    this.lastOnlineAt = data.last_online_at * 1000; // x1000 to convert it to milliseconds
    this.onlineStatus = data.online_status;
    this.experience = data.experience;
    this.experienceLevel = data.experience_level;
    this.name = this.user.fullName;
    this.selected = false;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }
}

export class PlayerGamePlayer {
  id: number;
  player: Player;
  score: number;
  eloDelta: number;
  scorePercentage: number;
  inviteStatus: InviteStatus;

  constructor(data: PlayerGamePlayerDto) {
    this.id = data.id;
    this.player = new Player(data.player);
    this.score = data.score;
    this.eloDelta = data.elo_delta;
    this.inviteStatus = data.invite_status;
  }

  computeScorePercentage(totalPoints: number): void {
    this.scorePercentage = (this.score / totalPoints) * 100;
  }
}
