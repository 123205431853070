import { ProblemTileDto } from "./ProblemTileDto";

export class ProblemTile {
  id: number;
  problemId: number;
  uid: string;
  isCorrect: boolean;
  answered: boolean;
  points: number;
  flexSpace: string;

  constructor(data: ProblemTileDto) {
    this.id = data.id;
    this.problemId = data.problem_id;
    this.uid = data.uid;
    this.isCorrect = data.is_correct;
    this.answered = data.answered;
    this.points = data.points;
  }

  computeTileWidth(totalPoints: number): void {
    const tileWidthPercentage = (this.points / totalPoints) * 100;
    this.flexSpace = `0 1 calc(${tileWidthPercentage}%)`;
  }
}
