@if (profilePhotoUrl) {
  <div 
    class="sm-profile-pic-container" 
    [style.width]="profileSize"
    [style.height]="profileSize"
    [ngStyle]="{'backgroundImage': 'url(' + profilePhotoUrl +')'}">
  </div>
} @else {
  <span 
    class="material-symbols-outlined" 
    style="cursor: pointer;"
    [style.font-size]="profileSize"
    [ngStyle]="{'color': profileSection === 'header' ? 'var(--header-menu-item-color)' : 'var(--text-color-primary)'}">
    account_circle
  </span>
}
