import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-container.component.html',
  styleUrl: './profile-container.component.scss'
})
export class ProfileContainerComponent {
  @Input() profilePhotoUrl: string | undefined;
  @Input() profileSection: string = 'header';
  @Input() profileSize: string = '30px';
}
