import { GameStatus } from "../../../constants/GameStatus";
import { Urls } from "../../../constants/Urls";
import Utils from "../../../helpers/utils.helper";
import { Base } from "../Base";
import { NavigationData } from "../NavigationData";
import { Player, PlayerGamePlayer } from "../player/Player";
import { ProblemTile } from "../problem-tile/ProblemTile";
import { PlayerGameDto } from "./PlayerGameDto";

export class PlayerGame extends Base {
  gamePlayers: PlayerGamePlayer[] = [];
  cfgDomainsNames: string[];
  progress: string;
  cfgNumberOfProblems: number;
  cfgTimeLimitSeconds: number;
  cfgEloLevel: number;
  eloDelta: number;
  isCompleted: boolean;
  isStarted: boolean;
  cfgDomains: number[];
  cfgDomainNamesTruncated: string;
  gamePlaygroundRoute: NavigationData;
  gameDetailsRoute: NavigationData;
  problemTiles: ProblemTile[];
  isLoaded: boolean;
  totalPoints: number = 0;
  problemTilesChunks: ProblemTilesChunk[] = [];
  loadingPercentage: number = 0;
  status: GameStatus;
  correctProblems: number = 0;
  answeredProblems: number = 0;
  winner?: Player;

  constructor(data: PlayerGameDto) {
    super(data)
    this.totalPoints = 1000;//data.total_points;
    this.gamePlayers = data.player_game_players.map(p => {
      const gamePlayer = new PlayerGamePlayer(p);
      gamePlayer.computeScorePercentage(this.totalPoints);
      return gamePlayer;
    });
    this.cfgDomainsNames = data.cfg_domains_names;
    this.progress = data.progress;
    this.cfgNumberOfProblems = data.cfg_number_of_problems;
    this.cfgTimeLimitSeconds = data.cfg_time_limit_seconds;
    this.cfgEloLevel = data.cfg_elo_level;
    this.eloDelta = data.elo_delta;
    this.isCompleted = data.is_completed;
    this.isStarted = data.is_started;
    this.cfgDomains = data.cfg_domains;
    this.cfgDomainNamesTruncated = data.cfg_domains_names?.join(', ');
    this.problemTiles = data.problem_tiles ? data.problem_tiles.map(pt => new ProblemTile(pt)) : [];
    this.isLoaded = data.is_loaded;
    this.status = data.status;
    this.gamePlaygroundRoute = new NavigationData(`/${Urls.APP}/${Urls.PLAYGROUND}`, { gameId: data.id });
    this.loadingPercentage = (this.problemTiles.length / this.cfgNumberOfProblems) * 100;
    this.winner = data.winner ? new Player(data.winner) : undefined;
    this.correctProblems = data.correct_problems;
    this.answeredProblems = data.answered_problems;
    this.totalPoints = data.total_points;

    if (this.isCompleted) {
      this.gameDetailsRoute = new NavigationData(`/${Urls.APP}/${Urls.GAME_COMPLETED}`, { gameId: data.id });
    }
  }

  processProblemTiles(): void{
    const chunkArray = Utils.splitArrayIntoChunks(this.problemTiles, 5);
    for (let index = 0; index < chunkArray.length; index++) {
      const selectedChunk = chunkArray[index];
      const problemTilesChunk = new ProblemTilesChunk(selectedChunk);
      this.problemTilesChunks.push(problemTilesChunk);
    }
  }
}

export class ProblemTilesChunk {
  totalPoints: number;
  problemTiles: ProblemTile[];

  constructor(problemTilesChunk: ProblemTile[]) {
    const selectedChunkTotalPoints = problemTilesChunk.reduce((totalPoints, { points }) => totalPoints + points, 0);
    problemTilesChunk.forEach(pt => {
      pt.computeTileWidth(selectedChunkTotalPoints);
    });

    this.totalPoints = selectedChunkTotalPoints;
    this.problemTiles = problemTilesChunk;
  
  }
}
